body {
  overflow: hidden;
}

.left-pane {
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
  padding-left: 5%;
  padding-right: 5%;
}

.left-pane-avatar {
  margin-left: 1rem;
  margin-top: 1rem;
}

.left-pane-name-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.left-pane-nick-name {
  text-align: center;
  font-size: 2em;
  padding: 0;
  margin: 0;
  margin-bottom: -10%;
}

.left-pane-real-name {
  text-align: center;
  font-size: 2.7vw;
  padding: 0;
  margin: 0;
  /* margin-top: -10%; */
}

.left-pane-social-icons {
  margin-top: 2vw;
  margin-left: 2vw;
  margin-right: 2vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.left-pand-tex-sec {
  margin-top: 5vh;
  margin-left: 2vw;
}

.left-pane-school-exp {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.right-pane {
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
  padding-left: 5%;
}

.right-pane-content {
  padding-right: 5%;
}

.right-pane-card-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;
}

.right-pane-card-title-sec {
  text-align: center;
  width: 35%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 5px;
}

.right-pane-card-logo-text {
  display: inline-block;
  line-height: 0.9;
  text-align: center;
}

.right-pane-card-tex-area {
  width: 100%;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.right-pane-card-title-text {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.right-pane-card-title-text div {
  width: 100%;
}

.right-pane-card-title-text p {
  margin: 0%;
}

.right-pane-card-tex-paper-title * {
  margin: 0;
}

.right-pane-card-tex-paper-title {
  margin-bottom: 30px;
}

.dark-mode-button-container {
  position: relative;
  float: right;
  top: 10px;
  right: 10px;
  height: 0;
}

.copyright-symbol {
  text-align: center;
}
